<template>
<div>
  <router-view></router-view>
</div>
</template>

<script>
export default {
  name: "second_layout",
  data() {
    return {
      SkipSwitchName: "",
    }
  }
}
</script>

<style scoped>



</style>
